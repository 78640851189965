import React from 'react';
import PropTypes from 'prop-types';
import { NegotiationDealerDTO } from '@ovex/annual-target-web-api';

import { Button, ButtonBar, Modal, Tabs } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import { AlertTypeEnum } from '../../../../common/objects';
import { useAlertBusOvex } from '../../../../common/hooks';
import { NegotiationTypeEnum } from '../../../utils/const';
import YearSettingsTexts from '../AnnualTargetYearSettings/YearSettingsTexts/YearSettingsTexts';
import NegotiationRow from '../NegotiationsTable/NegotiationRow';

import NegotiationTabs from './NegotiationTabs';
import NegotiationHeaderForm from './NegotiationHeaderForm/NegotiationHeaderForm';
import NegotiationDealerListForm from './NegotiationDealerListForm/NegotiationDealerListForm';
import NegotiationModelGroupListForm from './NegotiationModelGroupList/NegotiationModelGroupListForm';

import './NegotiationModalForm.scss';

const propTypes = {
  className: PropTypes.string,
  dealerList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationDealerDTO)),
  editRules: PropTypes.arrayOf(PropTypes.string),
  negotiation: PropTypes.instanceOf(NegotiationRow).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  shown: PropTypes.bool
};

const defaultProps = {
  className: null,
  dealerList: null,
  editRules: null,
  onSave: null,
  shown: false
};

const ModalPageTitleLabels = {
  detail: {
    [NegotiationTypeEnum.ADDITION]: 'ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_ADDITIONS_DETAIL',
    [NegotiationTypeEnum.CONTRACT]: 'ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_CONTRACTS_DETAIL',
    [NegotiationTypeEnum.CLOSURE]: 'ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_CLOSURES_DETAIL'
  },
  update: {
    [NegotiationTypeEnum.ADDITION]: 'ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_ADDITIONS_UPDATE',
    [NegotiationTypeEnum.CONTRACT]: 'ANNUAL_TARGET.PAGE_TITLE.NEGOTIATION_CONTRACTS_UPDATE'
  }
};

const NegotiationModalForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const readOnly = props.onSave == null;
  const negotiationType = props.negotiation.negotiationType;

  const classNames = ['ovex-NegotiationModalForm'];
  props.className && classNames.push(props.className);

  const [ rocForModels, setRocForModels ] = React.useState(props.negotiation.atPeriodList);
  const [ selectedTab, setSelectedTab ] = React.useState(NegotiationTabs.header);
  const handleSetSelectedTab = React.useCallback(
    (opt) => setSelectedTab(opt.tab.props.name),
    []
  );

  const tabFormValues = React.useRef({});

  const headerFormRef = React.useRef(null);
  const dealersFormRef = React.useRef(null);
  const modelGroupsFormRef = React.useRef(null);

  const onSave = props.onSave;
  const handleSave = React.useCallback(() => {
    if (headerFormRef.current.isValid()) {
      headerFormRef.current.save();

      if (dealersFormRef.current.isValid()) {
        dealersFormRef.current.save();

        if (modelGroupsFormRef.current.isValid()) {
          modelGroupsFormRef.current.save();

          onSave && onSave({
            negotiationId: props.negotiation.id,
            header: tabFormValues.current[NegotiationTabs.header],
            dealerList: tabFormValues.current[NegotiationTabs.dealers],
            modelGroupList: tabFormValues.current[NegotiationTabs.modelGroups]
          });
        } else {
          setSelectedTab(NegotiationTabs.modelGroups);
        }
      } else {
        setSelectedTab(NegotiationTabs.dealers);
      }
    } else {
      setSelectedTab(NegotiationTabs.header);
    }
  }, [onSave, props.negotiation.id]);

  const handleSaveTabFrom = React.useCallback(
    ({ formId, values }) => {
      tabFormValues.current[formId] = values;
    },
    []
  );

  const getHeaderLabel = () => {
    const lsiCodes = readOnly ? ModalPageTitleLabels.detail : ModalPageTitleLabels.update;
    return lsi.getLSIItem(lsiCodes?.[negotiationType]);
  };

  const handleUpdateModels = (data) => {
    const initialRocForModel = {};
    rocForModels.forEach(rm => {
      initialRocForModel[rm.unitOrder] = rm.negotiable;
    });
    const changed = data.some(dat => !dat.negotiable && initialRocForModel[dat.unitOrder] !== dat.negotiable);
    if (changed) {
      handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.MODEL_NEGOTIATION_DELETED_VALUES', AlertTypeEnum.WARNING);
    }
    setRocForModels(data);
  };

  const yearShiftList = React.useMemo(
    () => {
      const yearShiftSet = new Set();
      props.negotiation.modelGroupList.forEach(mg => mg.productionCapacityATPeriodList.forEach(p => yearShiftSet.add(p.yearShift)));
      return Array.from(yearShiftSet).sort();
    },
    [props.negotiation.modelGroupList]
  );

  return (
    <Modal
      className={classNames.join(' ')}
      header={getHeaderLabel()}
      onClose={props.onClose}
      shown={props.shown}
      size={((selectedTab === NegotiationTabs.modelGroups && !props.negotiation.isNegotiationClosureRow()) ||
        ( !props.negotiation.isNegotiationActivated() && !readOnly && selectedTab === NegotiationTabs.dealers))
        ? 'xl' : 'l'}
    >
      <Tabs
        activeName={selectedTab}
        onChange={handleSetSelectedTab}
      >
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.HEADER')}
          name={NegotiationTabs.header}
        >
          <NegotiationHeaderForm
            atPeriodList={props.negotiation.atPeriodList}
            atSalesPeriodList={props.negotiation.atSalesPeriodList}
            coefficient={props.negotiation.coefficient}
            editRules={props.editRules}
            formId={NegotiationTabs.header}
            negotiationName={props.negotiation.name}
            negotiationType={negotiationType}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            proposalApprovalFrom={props.negotiation.proposalApprovalFrom}
            proposalApprovalTo={props.negotiation.proposalApprovalTo}
            proposalDealerFrom={props.negotiation.proposalDealerFrom}
            proposalDealerTo={props.negotiation.proposalDealerTo}
            ref={headerFormRef}
            updateModels={handleUpdateModels}
            year={props.negotiation.year}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.DEALERS')}
          name={NegotiationTabs.dealers}
        >
          <NegotiationDealerListForm
            dealerList={props.dealerList}
            editRules={props.editRules}
            formId={NegotiationTabs.dealers}
            negotiationDealerList={props.negotiation.dealerList}
            negotiationType={negotiationType}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            ref={dealersFormRef}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.MODELS')}
          name={NegotiationTabs.modelGroups}
        >
          <NegotiationModelGroupListForm
            atPeriodList={rocForModels}
            editRules={props.editRules}
            formId={NegotiationTabs.modelGroups}
            modelGroupList={props.negotiation.modelGroupList}
            negotiationType={negotiationType}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            ref={modelGroupsFormRef}
            yearCurrent={props.negotiation.year}
            yearShiftList={yearShiftList}
          />
        </Tabs.Item>
        { negotiationType !== NegotiationTypeEnum.CLOSURE &&
          <Tabs.Item
            header={lsi.getLSIItem('ANNUAL_TARGET.TABS.NEGOTIATION_MODAL.TEXTS')}
            name={NegotiationTabs.texts}
          >
            <YearSettingsTexts
              formId={NegotiationTabs.texts}
              textMap={props.negotiation.textMap}
            />
          </Tabs.Item>
        }
      </Tabs>
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
        hidden={readOnly}
      >
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
});

NegotiationModalForm.propTypes = propTypes;
NegotiationModalForm.defaultProps = defaultProps;

export default NegotiationModalForm;
