import { EXCEL_STYLES_IDS } from '../../../../../common/components/ag-grid';
import { NegotiationTypeEnum } from '../../../../utils/const';
import { booleanAGGridFormatter } from '../../../../../common/utils/formatters';
import { YEAR_SHIFT_CURRENT_YEAR } from '../NegotiationModalFrom.consts';

import {
  editableProdCapacityValueGetter,
  editableProdCapacityValueParser,
  editableProdCapacityValueSetter,
  handleModelMixRatioValueFormatter,
  handleNegotiableValueSetter,
  isEditableNegotiation,
  isEditableProdCapacityCell,
  negotiationValueGetter,
  periodHeaderValueGetter,
  productionCapacityTotalTooltipValueGetter,
  productionCapacityTotalValueGetter,
  productionCapacityValueFormatter
} from './NegotiationModelGroupListForm.helpers';

export const COLUMN_IDS = {
  MODEL_GROUP: 'modelGroup',
  MODEL_GROUP_NAME: 'modelGroupName',
  MODEL_MIX_RATIO: 'modelMixRatio',
  NEGOTIABLE: 'negotiable'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  PERIOD_CD: 'periodDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.PERIOD_CD]: {
    cellClass: ['ovex-ag-grid--td-right', 'ovex-NegotiationModelGroupListForm-productionCapacity'],
    cellClassRules: {
      'ovex-NegotiationModelGroupListForm-cell--editable': isEditableProdCapacityCell,
      'ovex-NegotiationModelGroupListForm-cell--invalid': (cellClassParams) => {
        return isEditableProdCapacityCell(cellClassParams) && !cellClassParams.data.isPeriodValid(cellClassParams.colDef.ovexContext);
      },
      'ovex-NegotiationModelGroupListForm-cell--modified': (cellClassParams) => {
        return cellClassParams.data.isModified(cellClassParams.colDef.ovexContext);
      },
      'ovex-NegotiationModelGroupListForm-cell--modified-not-editable': (cellClassParams) => {
        return !isEditableProdCapacityCell(cellClassParams) && cellClassParams.data.isModified(cellClassParams.colDef.ovexContext);
      }
    },
    editable: isEditableProdCapacityCell,
    headerValueGetter: periodHeaderValueGetter,
    menuTabs: [],
    suppressMovable: true,
    valueFormatter: productionCapacityValueFormatter,
    valueGetter: editableProdCapacityValueGetter,
    valueParser: editableProdCapacityValueParser,
    valueSetter: editableProdCapacityValueSetter,
    width: 72
  }
};

export const getColumnDefinitionDataClosure = (lsi) => {
  const columnDefs = [
    {
      colId: COLUMN_IDS.MODEL_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL'),
      cellClass: EXCEL_STYLES_IDS.STRING,
      valueGetter: 'data && data.modelGroup',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 80,
      pinned: 'left'
    },
    {
      colId: COLUMN_IDS.MODEL_GROUP_NAME,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_NAME'),
      valueGetter: 'data && data.modelGroupName',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 790,
      pinned: 'left'
    }
    ]

  return {
    columnDefs: columnDefs,
    columnIdCurrentYearList: null
  };
}

export const getColumnDefinitionData = (lsi, negotiationType, yearShiftList, unitOrderList, yearCurrent) => {
  const pcrColumnData = getProductionCapacityRatioColumnData(lsi, negotiationType, yearShiftList, unitOrderList, yearCurrent);

  const columnDefs = [
    {
      colId: COLUMN_IDS.MODEL_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL'),
      cellClass: EXCEL_STYLES_IDS.STRING,
      valueGetter: 'data && data.modelGroup',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 80,
      pinned: 'left'
    },
    {
      colId: COLUMN_IDS.MODEL_GROUP_NAME,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_NAME'),
      valueGetter: 'data && data.modelGroupName',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 150,
      pinned: 'left'
    },
    {
      colId: COLUMN_IDS.MODEL_MIX_RATIO,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_MIX_RATIO_ABBR'),
      headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.MODEL_MIX_RATIO'),
      cellClass: ['ovex-ag-grid--td-right'],
      valueGetter: 'data && data.modelMixRatio',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 90,
      valueFormatter: handleModelMixRatioValueFormatter,
      hide: [NegotiationTypeEnum.ADDITION].includes(negotiationType)
    },
    {
      colId: COLUMN_IDS.NEGOTIABLE,
      cellClassRules: {
        'ovex-NegotiationModelGroupListForm-cell--editable': isEditableNegotiation
      },
      cellEditor: 'agRichSelectCellEditor',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NEGOTIABLE_SHORT'),
      headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.NEGOTIABLE'),
      cellEditorParams: { values: [true, false] },
      editable: isEditableNegotiation,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      valueFormatter: booleanAGGridFormatter,
      valueGetter: negotiationValueGetter,
      valueSetter: handleNegotiableValueSetter,
      width: 82
    },
    ...pcrColumnData.productionCapacityRationColumnDefs
  ];

  return {
    columnDefs: columnDefs,
    columnIdCurrentYearList: pcrColumnData.columnIdCurrentYearList
  };
};

const getProductionCapacityRatioColumnData = (lsi, negotiationType, yearShiftList, unitOrderList, yearCurrent) => {
  const columnIdCurrentYearList = [];

  const productionCapacityRationColumnDefs = yearShiftList.map(yearShift => {

    const childrenColumns = unitOrderList.map(unitOrder => {
      const colId = getProductionCapacityRatioColId(yearShift, unitOrder);

      if (yearShift === YEAR_SHIFT_CURRENT_YEAR) {
        columnIdCurrentYearList.push(colId);
      }

      return {
        colId: colId,
        type: [COLUMN_TYPE_ENUM.PERIOD_CD],
        columnGroupShow: 'open',
        ovexContext: { yearShift: yearShift, unitOrder: unitOrder }
      };
    });

    // period TOTAL column
    childrenColumns.push({
      colId: getProductionCapacityRatioColIdTotal(yearShift),
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
      cellClass: ['ovex-ag-grid--td-right', 'ovex-NegotiationModelGroupListForm-productionCapacity', 'ovex-NegotiationModelGroupListForm-productionCapacity-total'],
      cellClassRules: {
        'ovex-NegotiationModelGroupListForm-cell--invalid': ({ data }) => !data.isTotalValid(yearShift)
      },
      valueGetter: productionCapacityTotalValueGetter(yearShift),
      valueFormatter: productionCapacityValueFormatter,
      tooltipValueGetter: productionCapacityTotalTooltipValueGetter(yearShift),
      menuTabs: [],
      suppressMovable: true,
      width: 90
    });

    return {
      groupId: getProductionCapacityRatioGroupId(yearShift),
      headerName: yearCurrent + yearShift,
      marryChildren: true,
      openByDefault: true,
      children: childrenColumns
    };
  });

  return {
    productionCapacityRationColumnDefs: productionCapacityRationColumnDefs,
    columnIdCurrentYearList: columnIdCurrentYearList
  };
};

const getProductionCapacityRatioGroupId = (yearShift) => `productionCapacityRatio_ys${yearShift}`;
const getProductionCapacityRatioColId = (yearShift, unitOrder) => `productionCapacityRatio_ys${yearShift}_uo${unitOrder}`;
const getProductionCapacityRatioColIdTotal = (yearShift) => `${getProductionCapacityRatioGroupId(yearShift)}_total`;
